import { RouteObject } from 'react-router'
import { HomePage, ManualQueryPage } from '@/pages'

export const routeConfig: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/manual-query',
    element: <ManualQueryPage />
  }
]
