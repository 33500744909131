import { ANT_PREFIXCLS } from '@/core'
import { ConfigProvider } from 'antd'
import { useRoutes } from 'react-router'
import { routeConfig } from './router'

function App() {
  const elements = useRoutes(routeConfig)
  return (
    <ConfigProvider prefixCls={ANT_PREFIXCLS} autoInsertSpaceInButton={false}>
      {elements}
    </ConfigProvider>
  )
}

export default App
