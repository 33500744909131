
export const APUTURE_PREFIXCLS = process.env.REACT_APP_APUTURE_NAME

export const SUCCESS_CODE = 200

/** SN码规则 */
export const SN_REGEXP = /^[a-zA-Z0-9]{1,30}$/

/** SN查询接口 */
export const API_GET_SN_DATA = '/sn/getSnData'

/** SN人工查询接口 */
export const API_POST_SN = '/sn/sendSnMail'