import cx from 'classnames'
import { Property } from 'csstype'
import { SVGAttributes } from 'react'
import './index.less'

type SvgProps = SVGAttributes<HTMLOrSVGElement> & {
  icon: string
  size?: 'large' | 'middle' | 'small' | '' | number
  pointer?: boolean
  pointerEvents?: Property.PointerEvents | undefined
}

const SvgIcon = (props: SvgProps) => {
  const {
    icon,
    className,
    size = 'large',
    pointer,
    pointerEvents,
    style,
    ...rest
  } = props
  const iconName = `#icon-${icon}`

  const rectWidth = typeof size === 'number' ? size : undefined

  return (
    <svg
      className={cx(
        className,
        'svg-icon',
        size ? `svg-icon-${size}` : '',
        `svg-icon-${icon}`,
        pointer && 'pointer',
      )}
      aria-hidden='true'
      style={{
        ...(style || {}),
        pointerEvents,
        ...(rectWidth
          ? {
              width: rectWidth,
              height: rectWidth,
            }
          : {}),
      }}
      {...rest}>
      <use xlinkHref={iconName}></use>
    </svg>
  )
}

export default SvgIcon
