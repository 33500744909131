import { attrAccept } from '@/utils'
import { Upload as AntUpload, Modal, UploadFile } from 'antd'
import { RcFile, UploadProps } from 'antd/lib/upload'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import SvgIcon from '../svgIcon'
import './index.less'

const getFileBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

function Upload(
  props: {
    fileList?: RcFile[]
    onChange?: (fileList: RcFile[]) => void
  } & Pick<UploadProps, 'accept' | 'multiple' | 'maxCount' | 'className'>,
) {
  const { className, fileList: OriFileList, onChange, ...restProps } = props
  const [fileList, setFileList] = useState<RcFile[]>(OriFileList || [])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImg, setPreviewImg] = useState<UploadFile>()

  const hadnleBeforeUpload: UploadProps['beforeUpload'] = (_, list) => {
    const files = restProps.accept
      ? list.filter((item) => attrAccept(item, restProps.accept!!))
      : list
    const newList = [...fileList, ...files]
    if (restProps.maxCount && restProps.maxCount < newList.length) {
      newList.length = restProps.maxCount
    }
    setFileList(newList)
    files.forEach((file) => {
      getFileBase64(file).then((res) => {
        ;(file as UploadFile).preview = res
        ;(file as UploadFile).thumbUrl = res
      })
    })
    return false
  }

  const handleOnRemove: UploadProps['onRemove'] = (file) => {
    const index = fileList.indexOf(file as unknown as RcFile)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    setFileList(newFileList)
  }

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getFileBase64(file.originFileObj as RcFile)
    }

    setPreviewImg(file)
    setPreviewOpen(true)
    // setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  }

  useEffect(() => {
    setFileList((list) => {
      if (list === OriFileList) {
        return list
      }
      return OriFileList || []
    })
  }, [OriFileList])

  useEffect(() => {
    onChange && onChange(fileList)
  }, [fileList, onChange])

  const uploadButton = (
    <div>
      <SvgIcon icon='picture_add' pointer={false} size={14} />
    </div>
  )

  return (
    <>
      <AntUpload
        className={cx('custom-upload', className)}
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleOnRemove}
        beforeUpload={hadnleBeforeUpload}
        {...restProps}>
        {fileList.length >= (props.maxCount || 1) ? null : uploadButton}
      </AntUpload>
      <Modal
        open={previewOpen}
        title={previewImg?.name}
        footer={null}
        onCancel={handleCancel}>
        <img
          alt={previewImg?.fileName}
          style={{ width: '100%' }}
          src={previewImg?.preview}
        />
      </Modal>
    </>
  )
}

export default Upload
