import Layout from '@/components/layout'
import SvgIcon from '@/components/svgIcon'
import { API_GET_SN_DATA, APUTURE_PREFIXCLS, SN_REGEXP } from '@/core'
import apiService, { isSuccessResult } from '@/core/request'
import { ISNCodeData } from '@/types'
import { Button, Input, InputRef } from 'antd'
import cx from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './index.less'
const example_1 = require('@/assets/images/sncode_example_01.png')
const example_2 = require('@/assets/images/sncode_example_02.png')
const example_3 = require('@/assets/images/sncode_example_03.png')

/** 查询初始状态 */
const QUERY_STATE_INITIAL = -1
/** 查询中 */
const QUERY_STATE_ING = 0
/** 查询成功 */
const QUERY_STATE_SUCCESS = 1
/** 查询失败 */
const QUERY_STATE_FAIL = 2


function HomePage() {
  const [queryState, setQueryState] = useState(QUERY_STATE_INITIAL)
  const [queryResult, setQueryResult] = useState<ISNCodeData | undefined>()
  const inputRef = useRef<InputRef>(null)
  const [submittable, setSubmittable] = useState(false)

  const handlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault()
      handlerQuery(event.currentTarget.value)
    }
  }

  const handlerInput = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value

      if (!SN_REGEXP.test(value)) {
        // 如果输入内容不符合要求
        event.currentTarget.value = value.replace(/[^\w]/gi, '')
      }
      setSubmittable(event.currentTarget.value?.length > 0)
    },
    [],
  )
  const handlerQuery = useCallback((code?: string) => {
    if (!code) {
      setQueryState(QUERY_STATE_INITIAL)
      setQueryResult(undefined)
      return
    }
    setQueryState(QUERY_STATE_ING)
    apiService
      .get<RequestResult<ISNCodeData>, RequestResult<ISNCodeData>>(
        API_GET_SN_DATA,
        {
          params: { sn: code },
        },
      )
      .then((res) => {
        if (isSuccessResult(res)) {
          setQueryResult(res.data)
          setQueryState(QUERY_STATE_SUCCESS)
        } else {
          setQueryState(QUERY_STATE_FAIL)
          setQueryResult(undefined)
        }
      })
      .catch((reason) => {
        setQueryState(QUERY_STATE_FAIL)
        setQueryResult(undefined)
      })
  }, [])

  const handlerSubmit = () => {
    const value = inputRef?.current?.input?.value
    handlerQuery(value)
  }

  return (
    <Layout className={cx(`${APUTURE_PREFIXCLS}-home`)}>
      <h2 className={cx(`${APUTURE_PREFIXCLS}-query-title`)}>官网正品查询</h2>
      <Input
        className={cx(`${APUTURE_PREFIXCLS}-query-input`)}
        size='large'
        prefix={<SvgIcon icon='search' size={32} />}
        placeholder='请输入S/N编码'
        ref={inputRef}
        onInput={handlerInput}
        onKeyDown={handlerKeyDown}
      />
      <div className={cx(`${APUTURE_PREFIXCLS}-result-container`)}>
        {queryState < QUERY_STATE_ING ||
        (QUERY_STATE_ING === queryState && !queryResult) ? (
          <div className={cx(`${APUTURE_PREFIXCLS}-result-tips`)}>
            <div className={cx(`${APUTURE_PREFIXCLS}-query-tip`)}>
              <SvgIcon icon='date' size={28} />
              <span>
                2021年1月1号以后购买的用户可以在灯体后部贴纸或控制盒贴纸上找到S/N编码。
              </span>
            </div>
            <div className={cx(`${APUTURE_PREFIXCLS}-query-examples`)}>
              <div className={cx(`${APUTURE_PREFIXCLS}-query-example-item`)}>
                <img
                  className={cx(`${APUTURE_PREFIXCLS}-query-example-img`)}
                  src={example_1}
                  alt=''
                />
              </div>
              <div className={cx(`${APUTURE_PREFIXCLS}-query-example-item`)}>
                <img
                  className={cx(`${APUTURE_PREFIXCLS}-query-example-img`)}
                  src={example_2}
                  alt=''
                />
              </div>
              <div className={cx(`${APUTURE_PREFIXCLS}-query-example-item`)}>
                <img
                  className={cx(`${APUTURE_PREFIXCLS}-query-example-img`)}
                  src={example_3}
                  alt=''
                />
              </div>
            </div>
          </div>
        ) : null}

        {queryState > QUERY_STATE_ING ||
        (QUERY_STATE_ING === queryState && queryResult) ? (
          <div className={cx(`${APUTURE_PREFIXCLS}-result-box`)}>
            <div className={cx(`${APUTURE_PREFIXCLS}-result-content`)}>
              <div className={cx(`${APUTURE_PREFIXCLS}-result-head`)}>
                查询结果
              </div>
              <div className={cx(`${APUTURE_PREFIXCLS}-result`)}>
                {(QUERY_STATE_SUCCESS === queryState || QUERY_STATE_ING === queryState && queryResult) ? (
                  <div className={cx(`${APUTURE_PREFIXCLS}-result-success`)}>
                    <p className='query-result-item'>
                      您输入的产品S/N编码验证为：
                      <span className='query-result-sn'>{queryResult?.sn}</span>
                    </p>
                    <p className='query-result-item'>
                      产品名称：
                      <span className='query-result-deivce-name'>
                        {queryResult?.device_name}
                      </span>
                    </p>
                    <p className='query-result-item'>
                      产品生产日期：
                      <span className='query-result-create-date'>
                        {queryResult?.create_date}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className={cx(`${APUTURE_PREFIXCLS}-result-fail`)}>
                    <p>
                      非常抱歉，您输入的商品S/N码未查询到结果，请您检查是否存在空格信息后，再次验证。
                    </p>
                    <p>
                      系统仅查询2021年之后出厂信息，如在2021年1月1日前出厂，您可以填写以下表单，官方人员协助您进行查询。
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={cx(`${APUTURE_PREFIXCLS}-result-bg`)} />
          </div>
        ) : null}
      </div>
      <div className={cx(`${APUTURE_PREFIXCLS}-query-foot`)}>
        <Button
          className={cx(`${APUTURE_PREFIXCLS}-query-button`)}
          disabled={!submittable}
          loading={queryState === QUERY_STATE_ING}
          type='primary'
          size='large'
          onClick={handlerSubmit}>
          {queryState > QUERY_STATE_ING ? '重新查询' : '点击查询'}
        </Button>
        <div className={cx(`${APUTURE_PREFIXCLS}-manual-query-tip`)}>
          <span>找不到S/N码或搜索无结果？</span>
          <Link
            to='/manual-query'
            className={cx(`${APUTURE_PREFIXCLS}-manual-query-link`)}>
            <span>人工查询</span>
            <SvgIcon icon='arrow_right' size={18} />
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
