import { pathJoin } from '@/utils'
import { SUCCESS_CODE } from './constants'
import axios from 'axios'
import { CustomAxiosInstance } from '@/types'

/** 接口请求base地址 */
export const BASE_URL = pathJoin(
  process.env.REACT_APP_BASE_API_PATH,
  process.env.REACT_APP_BASE_API,
)

export const isSuccessResult = (result?: RequestResult) =>
  SUCCESS_CODE == result?.code

const apiService = axios.create({
  baseURL: BASE_URL,
  timeout: 99999,
})

apiService.interceptors.response.use(
  (response) => {
    if (response.status === 200 || isSuccessResult(response.data)) {
      return response.data
    }
    return response.data || response
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.warn('取消调用接口')
      return
    }
  },
)

export default apiService as CustomAxiosInstance
