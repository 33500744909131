import Layout from '@/components/layout'
import SvgIcon from '@/components/svgIcon'
import Upload from '@/components/upload'
import {
  ANT_PREFIXCLS,
  API_POST_SN,
  APUTURE_PREFIXCLS,
  SN_REGEXP,
} from '@/core'
import apiService, { isSuccessResult } from '@/core/request'
import { isEmail, isPhone } from '@/utils'
import { Button, Form, Input, message } from 'antd'
import { RuleRender } from 'antd/lib/form'
import { RcFile } from 'antd/lib/upload'
import cx from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './index.less'

const filterImgs = <T extends File>(fileList: T[]): T[] => {
  if (
    fileList.some(
      (file) =>
        !(
          file.type.startsWith('image/') &&
          ['image/gif', 'image/jpg', 'image/jpeg', 'image/png'].includes(
            file.type.toLocaleLowerCase(),
          )
        ),
    )
  ) {
    return fileList.filter((file) => {
      return (
        file.type.startsWith('image/') &&
        ['image/gif', 'image/jpg', 'image/jpeg', 'image/png'].includes(
          file.type.toLocaleLowerCase(),
        )
      )
    })
  }
  return fileList
}

function ManualQueryPage() {
  const [isSuccessArea, showSuccessArea] = useState(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState(false)
  const [onSubmitting, setSubmitting] = useState(false)
  const [deviceImgs, setDeviceImgs] = useState<RcFile[]>([])
  const [buyImgs, setBuyImgs] = useState<RcFile[]>([])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(deviceImgs.length > 0)
      },
      () => {
        setSubmittable(false)
      },
    )
  }, [form, values, deviceImgs])

  const phoneRuleRender: RuleRender = (form) => {
    return {
      validator(rule, value) {
        if (isPhone(value) || !value?.length) {
          return Promise.resolve()
        }
        return Promise.reject('手机号格式错误')
      },
    }
  }

  const emailRuleRender: RuleRender = (form) => {
    return {
      validator(rule, value) {
        if (isEmail(value) || !value?.length) {
          return Promise.resolve()
        }
        return Promise.reject('邮箱格式错误')
      },
    }
  }

  const onDeiviceImgsChange = useCallback<(fileList: RcFile[]) => void>(
    (fileList) => {
      setDeviceImgs(filterImgs(fileList))
    },
    [setDeviceImgs],
  )
  const onBuyImgsChange = useCallback<(fileList: RcFile[]) => void>(
    (fileList) => {
      setBuyImgs(filterImgs(fileList))
    },
    [setBuyImgs],
  )

  const handleSNInput = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value

      if (!SN_REGEXP.test(value)) {
        // 如果输入内容不符合要求
        event.currentTarget.value = value.replace(/[^\w]/gi, '')
      }
    },
    [],
  )

  const handleSubmit = () => {
    const data = {
      ...values,
      device_img: deviceImgs,
      buy_img: buyImgs,
    }
    const formData = new FormData()
    if (data) {
      Object.keys(data).forEach((key) => {
        var value = data[key]
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(''.concat(key, '[]'), item)
          })
          return
        }

        formData.append(key, value as any)
      })
    }
    setSubmitting(true)
    apiService
      .postForm<RequestResult, RequestResult>(API_POST_SN, formData)
      .then((res) => {
        if (isSuccessResult(res)) {
          showSuccessArea(true)
          return
        }
        message.error(`提交失败：${res.msg}`, 2)
      })
      .catch((reson) => {
        message.error('提交失败', 3)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const backToForm = () => {
    showSuccessArea(false)
    form.resetFields()
    setDeviceImgs([])
    setBuyImgs([])
  }

  return (
    <Layout className={cx(`${APUTURE_PREFIXCLS}-manual`)}>
      <Link to='/' className={cx(`${APUTURE_PREFIXCLS}-query-link`)}>
        <SvgIcon icon='arrow_left' size={18} />
        <span>通过S/N码查询</span>
      </Link>
      <h2 className={cx(`${APUTURE_PREFIXCLS}-query-title`)}>人工查询</h2>
      {isSuccessArea ? (
        <div className={cx(`${APUTURE_PREFIXCLS}-submit-result`)}>
          <div className={cx(`${APUTURE_PREFIXCLS}-submit-result-box`)}>
            <SvgIcon
              icon='email_success'
              size={100}
              className='icon-email-success'
            />
            <div className='result-description'>
              <p>您提交的信息我们已提交官方客服邮箱，</p>
              <p>我们预计会在72小时内以邮件的形式回复你查询结果。</p>
            </div>
          </div>
          <div
            className={cx(`${APUTURE_PREFIXCLS}-manual-query-back`)}
            onClick={backToForm}>
            <span>继续人工查询</span>
            <SvgIcon icon='arrow_right' size={18} />
          </div>
        </div>
      ) : (
        <Form
          className={cx(`${APUTURE_PREFIXCLS}-manual-query-form`)}
          layout='vertical'
          form={form}
          colon={false}
          labelAlign='left'>
          <Form.Item
            label={
              <>
                S/N码
                <span className={`${ANT_PREFIXCLS}-form-item-optional`}>
                  （非必填）
                </span>
              </>
            }
            name='sn_num'
            initialValue={''}
            rules={[
              {
                required: false,
                pattern: SN_REGEXP,
                message: 'S/N码格式错误',
              },
            ]}>
            <Input onInput={handleSNInput} />
          </Form.Item>
          <Form.Item
            label='购买渠道'
            name='buy_channels'
            rules={[
              { required: true, whitespace: true, message: '请填写购买渠道' },
              { max: 30, message: '购买渠道限制30个字符' },
            ]}>
            <Input autoComplete='off' />
          </Form.Item>
          <Form.Item
            label={
              <>
                产品图片上传
                <span className={`${ANT_PREFIXCLS}-form-item-optional`}>
                  （最大不超过5M）
                </span>
              </>
            }
            required
            rules={[{ required: true, message: '请上传产品图片' }]}>
            <Upload
              multiple
              maxCount={5}
              accept='.gif,.jpg,.jpeg,.png'
              fileList={deviceImgs}
              onChange={onDeiviceImgsChange}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                购买凭证截图
                <span className={`${ANT_PREFIXCLS}-form-item-optional`}>
                  （最大不超过5M）
                </span>
              </>
            }>
            <Upload accept='.gif,.jpg,.jpeg,.png' onChange={onBuyImgsChange} />
          </Form.Item>
          <Form.Item
            label='联系人'
            name='user_name'
            rules={[
              { required: true, whitespace: true, message: '请填写联系人' },
              { max: 30, message: '联系人限制30个字符' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label='联系电话'
            name='phone_number'
            rules={[
              { required: true, message: '请填写联系电话' },
              phoneRuleRender,
            ]}>
            <Input type='tel' />
          </Form.Item>
          <Form.Item
            label='邮箱地址'
            name='e_mail'
            extra={
              <div className='form-item-help'>
                <SvgIcon icon='email' size={28} />
                <span>查询信息将会通过邮件形式回复。</span>
              </div>
            }
            rules={[
              { required: true, message: '请填写邮箱地址' },
              emailRuleRender,
            ]}>
            <Input />
          </Form.Item>

          <div className={`${APUTURE_PREFIXCLS}-submit-button-wrapper`}>
            <Button
              className={cx(`${APUTURE_PREFIXCLS}-submit-button`)}
              disabled={!submittable}
              loading={onSubmitting}
              type='primary'
              htmlType='submit'
              onClick={handleSubmit}
              size='large'>
              点击提交
            </Button>
          </div>
        </Form>
      )}
    </Layout>
  )
}

export default ManualQueryPage
