import { APUTURE_PREFIXCLS } from '@/core'
import cx from 'classnames'
import './index.less'

function Layout(
  props?: { children?: React.ReactNode } & React.HTMLAttributes<HTMLElement>,
) {
  const { children, className, ...mainProps } = props || {}
  return (
    <main className={cx(`${APUTURE_PREFIXCLS}-main`, className)} {...mainProps}>
      <div className={cx(`${APUTURE_PREFIXCLS}-container`)}>{children}</div>
    </main>
  )
}

export default Layout
